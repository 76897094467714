@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap");
@import url("colors-light.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color);
  font-weight: bold !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

a:hover {
  text-decoration: none !important;
  color: inherit !important;
}

nav {
  box-shadow: none !important;
}

.newslistitem {
  list-style: none;
}

.pinnedPost h5 {
  color: var(--accent-main);
}

.brand-logo {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newslist {
  margin: 0;
  /* height: 100%; */
}

.adcontainer {
  padding: 3px 0;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.main-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link {
  color: inherit;
}

.main-story {
  overflow: hidden;
}

.main-story-excerpt {
  visibility: visible;
}

.shortexcerpt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.list-wrapper {
  height: 100%;
}

.info {
  display: inline-block;
  width: 100%;
  /* background-image: linear-gradient(to right, rgb(231, 231, 231), white, white); */
  padding-left: 4px;
}

.info span {
  display: block;
  padding: 0px 4px;
  color: white;
  float: right;
}

#search-site input {
  width: auto;
}

#search-site button {
  height: 60px;
}

input[type="text"]:focus,
input[type="search"]:focus,
textarea:focus {
  border-bottom: 1px solid var(--accent-main) !important;
  box-shadow: 0 1px 0 0 var(--accent-main) !important;
}

.padding {
  padding: 10px 5px;
}

.react-calendar {
  width: auto !important;
  margin-bottom: 1em !important;
}

.react-calendar__month-view__days__day--weekend {
  color: var(--accent-main) !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
  background: var(--calendar-tile-active-bg) !important;
  color: var(--calendar-tile-active-fg) !important;
}

.react-calendar__tile--now {
  background: var(--calendar-tile-now-bg) !important;
  border: 1px solid var(--accent-main) !important;
  color: var(--calendar-tile-now-fg) !important;
}

@media only screen and (min-width: 600px) {
  p {
    font-size: 1.1em;
  }
}
@media only screen and (min-width: 992px) {
  p {
    font-size: 1.3em;
  }
}

.topflag {
  display: flex;
  justify-content: center;
}
